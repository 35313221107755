import { Box } from "@twilio-paste/core/box";
import { useState } from "react";
import { useSelector } from "react-redux";

import { AppState, EngagementPhase } from "../store/definitions";
import { EntryPoint } from "./EntryPoint";
import { LoadingPhase } from "./LoadingPhase";
import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { innerContainerStyles, outerContainerStyles } from "./styles/RootContainer.styles";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};

export function RootContainer() {
    const { currentPhase, expanded, showPreEngagementOnLoad, hideActiveEngagementOnLoad } = useSelector(
        ({ session, config }: AppState) => ({
            currentPhase: session.currentPhase,
            expanded: session.expanded,
            showPreEngagementOnLoad: config.showPreEngagementOnLoad,
            hideActiveEngagementOnLoad: config.hideActiveEngagementOnLoad
        })
    );
    const [loadingComplete, setLoadingComplete] = useState(currentPhase !== EngagementPhase.Loading);

    if (!loadingComplete && currentPhase !== EngagementPhase.Loading) {
        setLoadingComplete(true);

        if (
            (currentPhase === EngagementPhase.PreEngagementForm && showPreEngagementOnLoad) ||
            (currentPhase === EngagementPhase.MessagingCanvas && !hideActiveEngagementOnLoad)
        ) {
            const openChatButton = document.querySelector(
                '#twilio-webchat-widget-root div[title="Open chat"]'
            ) as HTMLElement;
            openChatButton?.click();
        }
    }

    return (
        <Box>
            <Box {...outerContainerStyles}>
                {expanded && (
                    <Box data-test="root-container" {...innerContainerStyles}>
                        {getPhaseComponent(currentPhase)}
                    </Box>
                )}
                <EntryPoint />
            </Box>
        </Box>
    );
}
