import { AnyAction, Reducer } from "redux";
import { Form } from "./FormBuilder/Form";
import { Field } from "./FormBuilder/FormField";
import {
    ACTION_CHANGE_ENGAGEMENT_PHASE,
    ACTION_CHANGE_EXPANDED_STATUS,
    ACTION_START_SESSION,
    ACTION_UPDATE_SESSION_DATA,
    ACTION_UPDATE_FORM_VALUE,
    ACTION_UPDATE_FORM_VALUES,
    ACTION_UPDATE_FORM_DEFINITION,
    ACTION_UPDATE_FORM_ELEMENT,
    ACTION_RESET_FORM,
    ACTION_UPDATE_CONVERSATION_STATE
} from "./actions/actionTypes";
import { EngagementPhase, FormValues, SessionState } from "./definitions";

const LOCAL_STORAGE_ITEM_ID = "TWILIO_WEBCHAT_WIDGET";

const initialState: SessionState = {
    currentPhase: EngagementPhase.Loading,
    expanded: false,
    preEngagementData: {}
};

export const SessionReducer: Reducer<SessionState, AnyAction> = (
    state: SessionState = initialState,
    action: AnyAction
): SessionState => {
    switch (action.type) {
        case ACTION_START_SESSION: {
            return {
                ...state,
                token: action.payload.token,
                conversationSid: action.payload.conversationSid,
                currentPhase: action.payload.currentPhase
            };
        }

        case ACTION_UPDATE_SESSION_DATA: {
            return {
                ...state,
                token: action.payload.token,
                conversationSid: action.payload.conversationSid
            };
        }

        case ACTION_UPDATE_CONVERSATION_STATE: {
            if (action.payload.conversationState === "closed") {
                const json = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_ID) || "");
                json.token = null;
                localStorage.setItem(LOCAL_STORAGE_ITEM_ID, JSON.stringify(json));
            }
            return {
                ...state
            };
        }
        case ACTION_CHANGE_EXPANDED_STATUS: {
            return {
                ...state,
                expanded: action.payload.expanded
            };
        }

        case ACTION_CHANGE_ENGAGEMENT_PHASE: {
            return {
                ...state,
                currentPhase: action.payload.currentPhase
            };
        }

        case ACTION_UPDATE_FORM_DEFINITION: {
            const formStatePath = action.payload.formStatePath as string[];
            const definition = action.payload.form as Form;

            // Find the form state by following the state path.
            const newState = { ...state };
            let node = newState as Record<string, unknown>;
            for (const segment of formStatePath) {
                node[segment] = { ...(node[segment] as Record<string, unknown>) };
                node = node[segment] as Record<string, unknown>;
            }
            const form = node as Form;

            // Update the form state.
            const oldElements = form.elements;
            form.elements = {};
            form.styles = definition.styles;
            form.onSubmit = definition.onSubmit;

            for (const [name, newElement] of Object.entries(definition.elements)) {
                const oldElement = oldElements?.[name] as Field | undefined;
                form.elements[name] = {
                    ...oldElement,
                    ...newElement
                };

                // Preserve existing values when updating the form definition.
                const oldValue = oldElement?.value;
                if (oldValue) {
                    (form.elements[name] as Field).value = oldValue;
                }
            }

            return newState;
        }

        case ACTION_UPDATE_FORM_ELEMENT: {
            const formStatePath = action.payload.formStatePath as string[];
            const name = action.payload.name as string;
            const properties = action.payload.properties as Record<string, unknown>;

            // Find the field state by following the state path.
            const newState = { ...state };
            let node = newState as Record<string, unknown>;
            for (const segment of formStatePath) {
                node[segment] = { ...(node[segment] as Record<string, unknown>) };
                node = node[segment] as Record<string, unknown>;
            }
            const form = node as Form;

            form.elements[name] = {
                ...form.elements[name],
                ...properties
            };

            return newState;
        }

        case ACTION_UPDATE_FORM_VALUE: {
            const statePath = action.payload.statePath as string[];
            const value = action.payload.value as string;

            // Find the field state by following the state path.
            const newState = { ...state };
            let node = newState as Record<string, unknown>;
            for (const segment of statePath) {
                node[segment] = { ...(node[segment] as Record<string, unknown>) };
                node = node[segment] as Record<string, unknown>;
            }
            const field = node as Field;
            field.value = value;

            return newState;
        }

        case ACTION_UPDATE_FORM_VALUES: {
            const statePath = action.payload.statePath as string[];
            const values = action.payload.values as FormValues;

            // Find the form state by following the state path.
            const newState = { ...state };
            let node = newState as Record<string, unknown>;
            for (const segment of statePath) {
                node[segment] = { ...(node[segment] as Record<string, unknown>) };
                node = node[segment] as Record<string, unknown>;
            }
            const form = node as Form;
            form.elements = { ...form.elements };

            // Update the field values.
            for (const [key, value] of Object.entries(values)) {
                const field = form.elements[key] as Field;
                if (field) field.value = value;
            }

            return newState;
        }

        case ACTION_RESET_FORM: {
            const statePath = action.payload.statePath as string[];

            // Find the form state by following the state path, but save the last node for special treatment (total wipeout).
            const newState = { ...state };
            let node = newState as Record<string, unknown>;
            for (const segment of statePath.slice(0, -1)) {
                node[segment] = { ...(node[segment] as Record<string, unknown>) };
                node = node[segment] as Record<string, unknown>;
            }
            node[statePath.slice(-1)[0]] = {};

            return newState;
        }

        default:
            return state;
    }
};
