import { Input } from "@twilio-paste/core";
import { useSelector } from "react-redux";
import type { HiddenField } from "../../store/FormBuilder/HiddenField";
import { AppState } from "../../store/definitions";
import { getStateData } from "./FormBuilder";

export function HiddenField(statePath: string[]) {
    const data = useSelector((state: AppState) => getStateData<HiddenField>(state, statePath));

    return <Input {...data.itemProps} key={data.key} name={data.id} id={data.id} value={data.value} type="hidden" />;
}
