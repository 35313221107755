import { Field, newField } from "./FormField";
import { NamedElement } from "./NamedElement";

export type TextAreaField = Field & {
    required?: boolean;
    placeholder?: string;
    rows?: number;
};

export function newTextAreaField(config: Partial<TextAreaField> & NamedElement): TextAreaField {
    return newField({
        ...config,
        elementType: "TextAreaField"
    });
}
