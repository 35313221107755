import { Box, Button as TwilioButton } from "@twilio-paste/core";
import { useSelector } from "react-redux";
import type { Button } from "../../store/FormBuilder/Button";
import { AppState } from "../../store/definitions";
import { buttonStyles } from "../styles/Transcript.styles";
import { getStateData } from "./FormBuilder";

export declare type ButtonType = "submit" | "button";
export declare type ButtonVariant = "primary" | "primary_icon" | "secondary" | "secondary_icon";

export function Button(statePath: string[]) {
    const data = useSelector((state: AppState) => getStateData<Button>(state, statePath));

    return (
        <Box {...buttonStyles} {...data.boxProps} key={data.key}>
            <TwilioButton {...data.itemProps} variant={data.variant} type={data.buttonType} onClick={data.onClick}>
                {data.content}
            </TwilioButton>
        </Box>
    );
}
