import { Field, newField } from "./FormField";
import { NamedElement } from "./NamedElement";

export type SelectField = Field & {
    options: Record<string, string>;
    required?: boolean;
};

export function newSelectField(config: Partial<SelectField> & NamedElement): SelectField {
    return Object.assign(
        newField({
            ...config,
            elementType: "SelectField"
        }),
        {
            options: { ...config.options }
        }
    );
}
