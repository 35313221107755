import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useDispatch, useSelector } from "react-redux";
import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { Tagline } from "./Tagline";
import { containerStyles, expandedContainerStyles } from "./styles/EntryPoint.styles";

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);

    if (expanded) {
        return (
            <Box
                data-test="entry-point-button"
                title="Minimize chat"
                onClick={() => dispatch(changeExpandedStatus({ expanded: false }))}
                {...expandedContainerStyles}
            >
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" />
            </Box>
        );
    }
    return (
        <Box
            data-test="entry-point-button"
            title="Open chat"
            onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
            {...containerStyles}
        >
            <ChatIcon decorative={false} title="Open chat" size="sizeIcon60" />
            <Tagline />
        </Box>
    );
};
