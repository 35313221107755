import { Field, newField } from "./FormField";
import { NamedElement } from "./NamedElement";

export type HiddenField = Field;

export function newHiddenField(config: Partial<HiddenField> & NamedElement): HiddenField {
    return newField({
        ...config,
        elementType: "HiddenField"
    });
}
