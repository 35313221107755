import { Text } from "@twilio-paste/core";
import { useSelector } from "react-redux";
import type { TextSection } from "../../store/FormBuilder/TextSection";
import { AppState } from "../../store/definitions";
import { getStateData } from "./FormBuilder";

export function TextSection(statePath: string[]) {
    const data = useSelector((state: AppState) => getStateData<TextSection>(state, statePath));

    return (
        <Text {...data.itemProps} key={data.key} as={data.htmlTag ?? "p"}>
            {data.content}
        </Text>
    );
}
