function isAsciiAlphanumeric(char: string) {
    if (char === null || char === undefined) return false;
    const charCode = char.charCodeAt(0);
    return (
        (charCode >= "0".charCodeAt(0) && charCode <= "9".charCodeAt(0)) ||
        (charCode >= "a".charCodeAt(0) && charCode <= "z".charCodeAt(0)) ||
        (charCode >= "A".charCodeAt(0) && charCode <= "Z".charCodeAt(0))
    );
}

export function sentenceToKebab(source: string): string {
    source = source?.trim();
    if (source === null || source.length === 0) return source;

    let result = "";

    for (let i = 0; i < source.length; i++) {
        const char = source[i];
        const previousChar = source[i - 1];

        if (isAsciiAlphanumeric(char)) {
            result += char.toLowerCase();
        } else if (previousChar === undefined || isAsciiAlphanumeric(previousChar)) {
            result += "-";
        }
    }
    return result;
}
