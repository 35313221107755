import { HelpText } from "@twilio-paste/core";
import { Box } from "@twilio-paste/core/box";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { useDispatch, useSelector } from "react-redux";
import type { TextField } from "../../store/FormBuilder/TextField";
import { updateFormValue } from "../../store/actions/genericActions";
import { AppState } from "../../store/definitions";
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { getStateData } from "./FormBuilder";

function Pattern(info: TextField): string | undefined {
    if (info) {
        if (info.pattern && info.pattern.toString() && info.pattern.toString().trim().length !== 0) {
            return info.pattern.toString(); // user-defined custom regex pattern
        } else if (info.textFieldType) {
            switch (info.textFieldType) {
                case "text": {
                    if (info.id && info.id === "friendlyname") {
                        return (
                            // remove first and ending slash so output: ^([a-zA-Z'.]+)+([w\s][a-zA-Z'.]{2,})+(\s)?([a-zA-Z]*)(\s)?([a-zA-Z]*)
                            new RegExp("^([a-zA-Z'.\\-]+)+([\\s][a-zA-Z'.\\-]{2,})+(\\s)?([a-zA-Z]*)(\\s)?([a-zA-Z]*)")
                                .toString()
                                .replace(/^\/|\/$/g, "")
                        );
                    }
                    break;
                }
                case "email": {
                    return (
                        // output: ^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])
                        new RegExp("^((?!\\.)[\\w\\-_.]*[^.])(@\\w+)(\\.\\w+(\\.\\w+)?[^.\\W])")
                            .toString()
                            .replace(/^\/|\/$/g, "")
                    );
                }
                case "tel": {
                    return (
                        // output: ^(\d{1,3}|)(\([0-9]{3}\) |[0-9]{3})[0-9]{3}[0-9]{4} [this allows up to 3 digits country code]
                        new RegExp("^(\\d{1,3}|)(\\([0-9]{3}\\) |[0-9]{3})[0-9]{3}[0-9]{4}")
                            .toString()
                            .replace(/^\/|\/$/g, "")
                    );
                }
                default: {
                    break;
                }
            }
        }
    }
    return ".+"; // return any character (except for line terminators)
}

function CustomValidationMessage(info: TextField): string {
    if (info && info.textFieldType) {
        switch (info.textFieldType) {
            case "email": {
                return "Please input a valid email address.";
            }
            case "tel": {
                return "Please input a 10 digit phone number.";
            }
            case "text": {
                if (info.id && info.id === "friendlyname") {
                    return "Please enter a first and last name.";
                }
                break;
            }
            default:
        }

        return "Please correct this.";
    }
    return "";
}

function CustomHelpText(info: TextField): JSX.Element {
    if (info && info.textFieldType) {
        switch (info.textFieldType) {
            case "tel": {
                return <HelpText>Enter numbers only without any spaces.</HelpText>;
            }
            default: {
                break;
            }
        }
    }
    return <></>;
}

export function TextField(statePath: string[]) {
    const dispatch = useDispatch();
    const data = useSelector((state: AppState) => getStateData<TextField>(state, statePath));

    return (
        <Box {...fieldStyles} {...data.boxProps} key={data.key}>
            <Label {...data.labelProps} htmlFor={data.id} required={data.required}>
                {data.label ?? data.name}
            </Label>
            <Input
                {...data.itemProps}
                id={data.id}
                name={data.id}
                value={data.value}
                type={data.textFieldType}
                placeholder={data.placeholder}
                required={data.required}
                pattern={Pattern(data)}
                onChange={(e) => {
                    dispatch(updateFormValue(statePath, e.target.value));
                    data.onChange?.(e);
                }}
                onInvalid={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target && e.target.validity) {
                        if (
                            e.target.validity.badInput ||
                            e.target.validity.patternMismatch ||
                            e.target.validity.rangeOverflow ||
                            e.target.validity.rangeUnderflow ||
                            e.target.validity.stepMismatch ||
                            e.target.validity.tooLong ||
                            e.target.validity.tooShort ||
                            e.target.validity.typeMismatch ||
                            e.target.validity.valid ||
                            e.target.validity.valueMissing ||
                            e.target.validity.patternMismatch
                        ) {
                            e.target.setCustomValidity(CustomValidationMessage(data));
                        } else {
                            e.target.setCustomValidity("");
                        }
                    }
                    data.onInvalid?.(e);
                }}
            />
            {CustomHelpText(data)}
        </Box>
    );
}
