import { getLiveChatHeader } from "../../utils/liveChatHeaderHelper";
import { store } from "../store";

export async function getWorker({ clientId }: { clientId: string }) {

    const requestUri = store.getState().config.serverUrl;
    
    return fetch(`${requestUri}/Workers?workerClientId=${clientId}`, {
        headers: getLiveChatHeader(),
        method: "GET"
    })
    .then(async (response) => response.json())
    .catch((err) => console.log("ERROR: ", err));
}
