import { Select } from "@twilio-paste/core";
import { Box } from "@twilio-paste/core/box";
import { Label } from "@twilio-paste/core/label";
import { useDispatch, useSelector } from "react-redux";
import type { SelectField } from "../../store/FormBuilder/SelectField";
import { updateFormValue } from "../../store/actions/genericActions";
import { AppState } from "../../store/definitions";
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { getStateData } from "./FormBuilder";

export function SelectField(statePath: string[]) {
    const dispatch = useDispatch();
    const data = useSelector((state: AppState) => getStateData<SelectField>(state, statePath));

    return (
        <Box {...fieldStyles} {...data.boxProps} key={data.key}>
            <Label {...data.labelProps} htmlFor={data.id} required={data.required}>
                {data.label ?? data.name}
            </Label>
            <Select
                {...data.itemProps}
                name={data.id}
                id={data.id}
                required={data.required}
                value={data.value}
                onChange={(e) => {
                    dispatch(updateFormValue(statePath, e.target.value));
                    data.onChange?.(e);
                }}
            >
                {Object.entries(data.options).map(([val, text], index) => {
                    return (
                        <option value={val} key={`${index}`}>
                            {text}
                        </option>
                    );
                })}
            </Select>
        </Box>
    );
}
