import { Checkbox } from "@twilio-paste/core";
import { Box } from "@twilio-paste/core/box";
import { Label } from "@twilio-paste/core/label";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { CheckboxField } from "../../store/FormBuilder/CheckboxField";
import { updateFormValue } from "../../store/actions/genericActions";
import { AppState } from "../../store/definitions";
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { getStateData } from "./FormBuilder";

export function CheckboxField(statePath: string[]) {
    const dispatch = useDispatch();
    const data = useSelector((state: AppState) => getStateData<CheckboxField>(state, statePath));
    const [indeterminate, setIndeterminate] = useState(data.indeterminate ?? false);

    return (
        <Box {...fieldStyles} {...data.boxProps} key={data.key}>
            <Checkbox
                {...data.itemProps}
                id={data.id}
                name={data.id}
                checked={data.value === "on"}
                required={data.required}
                indeterminate={indeterminate}
                onChange={(e) => {
                    dispatch(updateFormValue(statePath, e.target.checked ? "on" : ""));
                    setIndeterminate(false);
                    data.onChange?.(e);
                }}
            >
                <Label {...data.labelProps} htmlFor={data.id} required={data.required}>
                    {data.label ?? data.name}
                </Label>
            </Checkbox>
        </Box>
    );
}
