// Number of messages fetched at a time from Conversations SDK
export const MESSAGES_LOAD_COUNT = 20;

// The maximum number of characters allowed in message input
export const CHAR_LIMIT = 32 * 1024; // 32kB

// The height of the loading spinner box shown in the message list when fetching new messages
export const MESSAGES_SPINNER_BOX_HEIGHT = 50;

// The default welcome commeage when a chat starts
export const DEFAULT_WELCOME_MESSAGE = "Thank you for contacting U\u2011Haul!";

//
export const END_CHAT_MESSAGE =
    "Your chat has ended. If you have any questions or concerns, please reach out to us again.";
export const END_CHAT_WARNING = "Are you sure you want to end this chat?";
export const END_TASK_REASON = "Chat Ended by Customer.";
export const END_IDLE_CHAT_MESSAGE = "Your chat closed due to inactivity. To resume, please start a new chat. Thanks for being the best part of U\u2011Haul!";
export const END_IDLE_CHAT_TIMEOUT = 120;
export const END_IDLE_CHAT_QUERY_PARAMETER_TIMEOUT = "REACT_APP_IDLE_TIMEOUT";
export const END_IDLE_CHAT_QUERY_PARAMETER_SHOW = "SHOW_REACT_APP_IDLE_TIMEOUT";
//
export const URL_ENCONDED_CONTENT_TYPE_HEADER = "application/x-www-form-urlencoded";

//
export const RESTRICTED_USER_IDENTITIES = ["U-Haul", "system"]
