import { ChangeEventHandler, FormEventHandler } from "react";
import { RenderedElement, newRenderedElement } from "./RenderedElement";
import { TypedElement } from "./TypedElement";

export type Field = RenderedElement & {
    value: string;
    onChange?: ChangeEventHandler<HTMLElement & { value: unknown }>;
    onInvalid?: FormEventHandler<HTMLElement & { value: unknown }>;
    label?: string | JSX.Element | JSX.Element[];
    labelProps: Record<string, unknown>;
};

export function newField(config: Partial<Field> & TypedElement): Field {
    return Object.assign(newRenderedElement(config), {
        value: config.value ?? "",
        labelProps: { ...config.labelProps }
    });
}
