import { NamedElement } from "./NamedElement";
import { RenderedElement, newRenderedElement } from "./RenderedElement";

export type TextSection = RenderedElement & {
    content?: string | JSX.Element | JSX.Element[];
    htmlTag?: keyof JSX.IntrinsicElements;
};

export function newTextSection(config: Partial<TextSection> & NamedElement): TextSection {
    return Object.assign(
        newRenderedElement({
            ...config,
            elementType: "TextSection"
        })
    );
}
