import { sentenceToKebab } from "../../utils/stringCase";
import { TypedElement } from "./TypedElement";

export type Element = TypedElement & {
    key?: string;
    id: string;
};

export function newElement(config: Partial<Element> & TypedElement): Element {
    return {
        ...config,
        id: config.id ?? sentenceToKebab(config.name)
    };
}
