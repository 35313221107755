import { BoxStyleProps } from "@twilio-paste/box";
import { FormEventHandler } from "react";
import { formStyles } from "../../components/styles/PreEngagementFormPhase.styles";
import { Element } from "./Element";

export type Form = {
    styles: BoxStyleProps;
    onSubmit?: FormEventHandler<HTMLElement>;
    elements: Record<string, Element>;
};

export function newForm(config: Partial<Form>): Form {
    return {
        ...config,
        styles: { ...formStyles, ...config.styles },
        elements: { ...config.elements }
    };
}
