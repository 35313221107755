import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";
import { Button as ButtonData } from "../../store/FormBuilder/Button";
import { CheckboxField as CheckboxFieldData } from "../../store/FormBuilder/CheckboxField";
import { Element as ElementData } from "../../store/FormBuilder/Element";
import { Form } from "../../store/FormBuilder/Form";
import { HiddenField as HiddenFieldData } from "../../store/FormBuilder/HiddenField";
import { SelectField as SelectFieldData } from "../../store/FormBuilder/SelectField";
import { TextAreaField as TextAreaFieldData } from "../../store/FormBuilder/TextAreaField";
import { TextField as TextFieldData } from "../../store/FormBuilder/TextField";
import { TextSection as TextSectionData } from "../../store/FormBuilder/TextSection";
import { AppState } from "../../store/definitions";
import { isEmpty } from "../../utils/isEmpty";
import { Button } from "./Button";
import { CheckboxField } from "./CheckboxField";
import { HiddenField } from "./HiddenField";
import { SelectField } from "./SelectField";
import { TextAreaField } from "./TextAreaField";
import { TextField } from "./TextField";
import { TextSection } from "./TextSection";

function isButtonData(config: ElementData): config is ButtonData {
    return config.elementType === "Button";
}

function isCheckboxFieldData(config: ElementData): config is CheckboxFieldData {
    return config.elementType === "CheckboxField";
}

function isHiddenFieldData(config: ElementData): config is HiddenFieldData {
    return config.elementType === "HiddenField";
}

function isSelectFieldData(config: ElementData): config is SelectFieldData {
    return config.elementType === "SelectField";
}

function isTextAreaFieldData(config: ElementData): config is TextAreaFieldData {
    return config.elementType === "TextAreaField";
}

function isTextFieldData(config: ElementData): config is TextFieldData {
    return config.elementType === "TextField";
}

function isTextSectionData(config: ElementData): config is TextSectionData {
    return config.elementType === "TextSection";
}

function buildField(config: ElementData, formStatePath: string[]): JSX.Element {
    const statePath = [...formStatePath, "elements", config.name];
    if (isButtonData(config)) return Button(statePath);
    if (isCheckboxFieldData(config)) return CheckboxField(statePath);
    if (isHiddenFieldData(config)) return HiddenField(statePath);
    if (isSelectFieldData(config)) return SelectField(statePath);
    if (isTextAreaFieldData(config)) return TextAreaField(statePath);
    if (isTextFieldData(config)) return TextField(statePath);
    if (isTextSectionData(config)) return TextSection(statePath);
    return <p>Unsupported field type.</p>;
}

export function getStateData<T>(state: AppState, statePath: string[]): T {
    let target: Record<string, unknown> = state.session;
    for (const segment of statePath) {
        target = target?.[segment] as Record<string, unknown>;
    }
    return target as T;
}

export function FormBuilder(statePath: string[]) {
    const data = useSelector((state: AppState) => getStateData<Form>(state, statePath));

    if (isEmpty(data)) {
        return <></>;
    }
    return (
        <Box as="form" onSubmit={data.onSubmit} {...data.styles}>
            {Object.values(data.elements).map((elementConfig) => {
                elementConfig.key = `${elementConfig.name}`;
                return buildField(elementConfig, statePath);
            })}
        </Box>
    );
}
