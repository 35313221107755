import { JSONObject } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import HtmlReactParser from "html-react-parser";
import { useSelector } from "react-redux";
import { DEFAULT_WELCOME_MESSAGE } from "../constants";
import { getStoredWelcomeMessageData, storeWelcomeMessageData } from "../sessionDataHandler";
import { AppState, FormValues } from "../store/definitions";
import {
    welcomeMessageStyles,
    welcomeMessageOffsetStyles,
    welcomeMessageInlineBlock
} from "./styles/WelcomeMessage.styles";

// eslint-disable-next-line prefer-const
let selectedWelcomeMessage: string | null = null;

export const WelcomeMessage = () => {
    const config = useSelector((state: AppState) => state.config);
    const preEngagementData = useSelector((state: AppState) =>  (state.chat.conversation?.attributes as JSONObject).pre_engagement_data as FormValues) ?? {};
    selectedWelcomeMessage = "";
    let topic: string;
    if (Object.keys(preEngagementData).includes("type")) {
        topic = preEngagementData.type as string;
        if (config.welcomeMessages && config.welcomeMessages.length > 0) {
            config.welcomeMessages.map((x) => {
                if (x.topic === topic) {
                    selectedWelcomeMessage = x.welcomeMessage;
                    storeWelcomeMessageData(selectedWelcomeMessage);
                }
                return "";
            });
        }
    } else {
        selectedWelcomeMessage = getStoredWelcomeMessageData() as string;
    }

    const gm =
        selectedWelcomeMessage !== null && selectedWelcomeMessage !== "" ? (
            <>
                <p>{HtmlReactParser(selectedWelcomeMessage as string)}</p>
            </>
        ) : null;

    return (
        <Text as="p" {...welcomeMessageInlineBlock}>
            <Box as="span" {...welcomeMessageStyles}>
                <ChatIcon decorative={true} />
                <Box as="span" {...welcomeMessageOffsetStyles}>
                    {DEFAULT_WELCOME_MESSAGE}
                </Box>
            </Box>
            {gm}
        </Text>
    );
};
