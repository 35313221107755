import { ClientApps } from "../store/definitions";
import { getClientApp } from "./clientAppHelper";

function setProdHeader(liveChatHeader: Headers, clientApp: ClientApps, keyName: string) {
  if (clientApp) {
    switch (clientApp) {
      case ClientApps.PROD:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_PROD as string));
        break;
      case ClientApps.CAFCMS:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_CAFCMS_PROD as string));
        break;
      case ClientApps.CAFWEB:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_CAFWEB_PROD as string));
        break;
      case ClientApps.RACMS:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_RACMS_PROD as string));
        break;
      case ClientApps.RAWEB:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_RAWEB_PROD as string));
        break;
      case ClientApps.SANDRCMS:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_SANDRCMS_PROD as string));
        break;
      case ClientApps.DOTCOMWEB:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_DOTCOMWEB_PROD as string));
        break;
      case ClientApps.MHWEB:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_MHWEB_PROD as string));
        break;
      case ClientApps.REPAIRWEB:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_REPAIRWEB_PROD as string));
        break;
      case ClientApps.WSSWEB:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_WSSWEB_PROD as string));
        break;
      case ClientApps.POSWEB:
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_POSWEB_PROD as string));
        break;
      default:
        break;
    }
  }
}

export function getLiveChatHeader(): Headers {
  const keyName: string = "Ocp-Apim-Subscription-Key";
  const liveChatHeader: Headers = new Headers();
  try {
    liveChatHeader.set("Content-Type", "application/json; charset=utf-8");
    const clientApp: ClientApps = getClientApp();
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "DEV":
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_DEV) as string);
        break;
      case "QA":
        liveChatHeader.set(keyName, (process.env.REACT_APP_LIVE_CHAT_APIM_KEY_QA) as string);
        break;
      case "PROD":
        if (clientApp) {
          setProdHeader(liveChatHeader, clientApp, keyName);
        }
        break;
      default:
        break;
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams && urlParams.get("apiUrl")) {
      setProdHeader(liveChatHeader, clientApp, keyName);
    }
    
  } catch (e) {
    console.error(e);
  }
  return liveChatHeader;
}
