import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { AppState } from "../store/definitions";
import { taglineStyles, subTaglineStyles } from "./styles/EntryPoint.styles";

export const Tagline = () => {
    const entryPoint = useSelector((state: AppState) => state.config.componentProps?.EntryPoint);

    return (
        <Box as="span">
            <Box as="div" {...taglineStyles}>
                &nbsp;&nbsp;{entryPoint?.tagline || "Chat with us"}&nbsp;&nbsp;
            </Box>
            {entryPoint?.subTagline ? (
                <Box as="div" {...subTaglineStyles}>
                    &nbsp;&nbsp;{entryPoint?.subTagline}&nbsp;&nbsp;
                </Box>
            ) : (
                ""
            )}
        </Box>
    );
};
