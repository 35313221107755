import { Client, Conversation, Participant, Message, User } from "@twilio/conversations";
import { AlertVariants } from "@twilio-paste/core/alert";
import { GenericThemeShape } from "@twilio-paste/theme";

import {
    FileAttachmentConfig,
    TranscriptConfig,
    PreEngagementConfig,
    ComponentPropsConfig,
    WelcomeMessageConfig
} from "../definitions";

export enum EngagementPhase {
    PreEngagementForm = "PreEngagementForm",
    PostEngagementForm = "PostEngagementForm",
    MessagingCanvas = "MessagingCanvas",
    Loading = "Loading"
}

export enum ClientApps {
    DEV = "DEV",
    QA = "QA",
    PROD = "PROD",
    // CAF CMS
    CAFCMS = "CAFCMS",
    // CAF Web
    CAFWEB = "CAFWEB",
    // Roadsite CMS
    RACMS = "RACMS",
    // Roadsite Web
    RAWEB = "RAWEB",
    // Sales and Res CMS
    SANDRCMS = "SANDRCMS",
    // Uhaul.com
    DOTCOMWEB = "DOTCOMWEB",
    // MovingHelp
    MHWEB = "MHWEB",
    // Repair
    REPAIRWEB = "REPAIRWEB",
    // WSS
    WSSWEB = "WSSWEB",
    // POS
    POSWEB = "POSWEB"
};

export type ChatState = {
    conversationsClient?: Client;
    conversation?: Conversation;
    participants?: Participant[];
    users?: User[];
    messages?: Message[];
    attachedFiles?: File[];
    conversationState?: string;
};

export type FormValues = Record<string, string>;

export type SessionState = {
    currentPhase: EngagementPhase;
    expanded: boolean;
    token?: string;
    conversationSid?: string;
    conversationsClient?: Client;
    conversation?: Conversation;
    users?: User[];
    participants?: Participant[];
    messages?: Message[];
    conversationState?: "active" | "inactive" | "closed";
    preEngagementData: FormValues;
};

export type ConfigState = {
    serverUrl?: string;
    serviceUrl?: string;
    theme?: {
        isLight?: boolean;
        overrides?: Partial<GenericThemeShape>;
    };
    preEngagementConfig?: PreEngagementConfig;
    componentProps?: ComponentPropsConfig;
    fileAttachment?: FileAttachmentConfig;
    transcript?: TranscriptConfig;
    welcomeMessages?: WelcomeMessageConfig[];
    showPreEngagementOnLoad?: boolean;
    hideActiveEngagementOnLoad?: boolean;
    headerText?: string;
    clientApp?: ClientApps;
    isParkChatEnabled?: boolean;
};

export type Notification = {
    dismissible: boolean;
    id: string;
    onDismiss?: () => void;
    message: string;
    timeout?: number;
    type: AlertVariants;
};

export type NotificationState = Notification[];

export type AppState = {
    chat: ChatState;
    config: ConfigState;
    session: SessionState;
    notifications: NotificationState;
};
