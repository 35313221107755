import { URL_ENCONDED_CONTENT_TYPE_HEADER } from "../../constants";
import { sessionDataHandler } from "../../sessionDataHandler";
import { getLiveChatHeader } from "../../utils/liveChatHeaderHelper";
import { store } from "../store";

export async function cancelTask({ taskSid, reason }: { taskSid: string; reason: string }) {
    return fetch(`${process.env.REACT_APP_TWILIO_BASE_URL}CancelTask`, {
        headers: {
            "Content-Type": URL_ENCONDED_CONTENT_TYPE_HEADER
        },
        method: "POST",
        body: `taskSid=${taskSid}&reason=${reason}`
    });
}

export async function wrapUpTask({ taskSid, reason }: { taskSid: string; reason: string }) {
    return fetch(`${process.env.REACT_APP_TWILIO_BASE_URL}WrapUpTask`, {
        headers: {
            "Content-Type": URL_ENCONDED_CONTENT_TYPE_HEADER
        },
        method: "POST",
        body: `taskSid=${taskSid}&reason=${reason}`
    });
}

export async function getTasks({ conversationSid }: { conversationSid: string }) {
    return fetch(`${process.env.REACT_APP_TWILIO_BASE_URL}GetTasksByWebChannel`, {
        headers: {
            "Content-Type": URL_ENCONDED_CONTENT_TYPE_HEADER
        },
        method: "POST",
        body: `channelSid=${conversationSid}`
    });
}

export async function endTask({ taskSid, reason }: { taskSid: string; reason: string }) {
    return fetch(`${process.env.REACT_APP_TWILIO_BASE_URL}endChat`, {
        headers: {
            "Content-Type": URL_ENCONDED_CONTENT_TYPE_HEADER
        },
        method: "POST",        
        body: `taskSid=${taskSid}&reason=${reason}`
    });
}

export async function unhookParkedChat({ conversationSid, webhookSid }: { conversationSid: string, webhookSid: string }) {
    const serviceUrl = process.env.REACT_APP_TWILIO_DESKTOP_URL;    
    return fetch(`${serviceUrl}unhook-parked-chat`, {
        headers: {
            "Content-Type": URL_ENCONDED_CONTENT_TYPE_HEADER
        },
        method: "POST",        
        body: `conversationSid=${conversationSid}&webhookSid=${webhookSid}`
    });
}

export async function checkParkedChatFeatureEnabled() {
    return fetch(`${sessionDataHandler.getEndpoint()}/ParkFeatureToggle`,
        {
            method: "GET",
            headers: getLiveChatHeader()
        });
}

export async function logException(
    error: Error, 
    refMethodName: string, 
    customMessage?: string, 
    clientInfo?: string, 
    moreInfo?: string) {

    let exceptionDetails: string = "";
    let errorStackTrace: string = "";
    if (error) {
        exceptionDetails = error.message;
        errorStackTrace = error.stack || "";
    }
    const storeData = store.getState();
    const requestUri = storeData.config.serverUrl;
     if (!requestUri) {
         console.log("Exceptions logging not found.");
         return;
     }

     fetch(`${requestUri}/Exceptions`,
         {
             method: "post",
             headers: getLiveChatHeader(),
             body: JSON.stringify({
                 message: customMessage,
                 innerException: exceptionDetails,
                 stackTrace: errorStackTrace,
                 helpLink: window.location.href.toString(),
                 source: window.location.href.toString(),
                 methodName: refMethodName,
                 clientIp: clientInfo,
                 otherInfo: moreInfo
             }) 
         })
         .then(async (response : Response) => {
            console.log("test", response)
            return response.json()
         })
         .then((json) => {
             if(!json.Success)
             {
                 throw json.Errors ? json.Errors[0] : "Error encounterd logging exception.";
             }
         })
         .catch((err) => console.log("ERROR: ", err));
};
