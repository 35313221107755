import HtmlReactParser from "html-react-parser";
import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { PreEngagementConfig } from "../definitions";
import { notifications } from "../notifications";
import { getConversationDetail, sessionDataHandler, storeChatActive } from "../sessionDataHandler";
import { newButton } from "../store/FormBuilder/Button";
import { Form, newForm } from "../store/FormBuilder/Form";
import { Field } from "../store/FormBuilder/FormField";
import { newHiddenField } from "../store/FormBuilder/HiddenField";
import { SelectField } from "../store/FormBuilder/SelectField";
import { TextSection, newTextSection } from "../store/FormBuilder/TextSection";
import {
    addNotification,
    changeEngagementPhase,
    resetForm,
    updateFormDefinition,
    updateFormElement
} from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { isEmpty } from "../utils/isEmpty";
import { FormBuilder, getStateData } from "./FormBuilder/FormBuilder";
import { parseField, preSelectedFieldNames } from "./FormBuilder/RawFieldParser";
import { Header } from "./Header";
import { NotificationBar } from "./NotificationBar";
import { titleStyles, introStyles } from "./styles/PreEngagementFormPhase.styles";

const statePath = ["preEngagementData"];

function loadForm(config: PreEngagementConfig): Form {
    const { fields, preSelectedFields, greeting, description, disclaimer } = config;
    const form = newForm({});

    // Add the greeting and description elements.
    if (greeting !== undefined) {
        form.elements.greeting = newTextSection({
            name: "greeting",
            content: HtmlReactParser(greeting)
        });
    }

    if (description !== undefined) {
        form.elements.description = newTextSection({
            name: "description",
            content: HtmlReactParser(description)
        });
    }

    // Add hidden fields for pre-selected fields with values.
    for (const [key, value] of Object.entries(preSelectedFields)) {
        const name = preSelectedFieldNames[key] ?? key;
        if (value !== "") {
            form.elements[name] = newHiddenField({
                name: preSelectedFieldNames[key] ?? key,
                value
            });
        }
    }

    // Add regular fields.
    for (const rawConfig of fields) {
        const fieldConfig = parseField(rawConfig);
        form.elements[fieldConfig.name] = fieldConfig;
    }

    // Add disclaimer.
    if (disclaimer !== undefined) {
        form.elements.disclaimer = newTextSection({
            name: "disclaimer",
            content: HtmlReactParser(disclaimer),
        });
    }

    // Add submit button.
    form.elements.submit = newButton({
        name: "submit",
        content: "Begin chat"
    });
    return form;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function bindForm(form: Form, dispatch: Dispatch<any>): void {
    // Set initial styles.
    if (form.elements.customerLink) {
        (form.elements.customerLink as Field).boxProps = {
            display: "none",
            ...(form.elements.customerLink as Field)?.boxProps
        };
    }
    if (form.elements.entity) {
        (form.elements.entity as Field).boxProps = {
            display: "none",
            ...(form.elements.entity as Field)?.boxProps
        };
    }
    if (form.elements.greeting) {
        (form.elements.greeting as TextSection).itemProps = {
            ...titleStyles,
            ...(form.elements.greeting as Field)?.itemProps
        };
    }
    if (form.elements.description) {
        (form.elements.description as TextSection).itemProps = {
            ...introStyles,
            ...(form.elements.description as Field).itemProps
        };
    }
    if (form.elements.disclaimer) {
        (form.elements.disclaimer as TextSection).itemProps = {
            ...introStyles,
            ...(form.elements.disclaimer as Field).itemProps
        };
    }

    // Bind field change handlers.
    (form.elements.type as SelectField).onChange = (e) => {
        if (form.elements.customerLink) {
            if (e.target.value === "customer-service-agent") {
                dispatch(updateFormElement(statePath, "customerLink", { boxProps: { display: null } }));
            } else {
                dispatch(updateFormElement(statePath, "customerLink", { value: "", boxProps: { display: "none" } }));
            }
        }

        if (form.elements.entity) {
            if (e.target.value === "general-manager") {
                dispatch(updateFormElement(statePath, "entity", { boxProps: { display: null } }));
            } else {
                dispatch(updateFormElement(statePath, "entity", { value: "", boxProps: { display: "none" } }));
            }
        }
    };

    // Bind form submit handler.
    form.onSubmit = async (e: FormEvent) => {
        if (!(e.target as HTMLFormElement).checkValidity() ) {
            e.preventDefault();
        }

        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const entries = Object.fromEntries(new FormData(e.target as HTMLFormElement));

            if (entries.type === "recovery") {
                if (entries.unsafe) {
                    entries.question = `Unsafe: ${entries.unsafe}\n${entries.question}`;
                }
                if (entries.address) {
                    entries.question = `Address: ${entries.address}\n${entries.question}`;
                }
                if (entries.equipment) {
                    entries.question = `Equipment: ${entries.equipment}\n${entries.question}`;
                }
            }

            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: entries
            });

            dispatch(
                initSession({
                    token: data.token,
                    conversationSid: data.conversationSid
                })
            );
            storeChatActive(true);
            //Raise event when chat has started
            const rootElement = document.getElementById("twilio-webchat-widget-root");
            if (rootElement) {
                const event = new CustomEvent("chat_started",{
                    detail: getConversationDetail()});
                rootElement.dispatchEvent(event);
            }

            dispatch(resetForm(statePath));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };
}

export function PreEngagementFormPhase() {
    const config = useSelector((state: AppState) => state.config.preEngagementConfig);
    const formState = useSelector((state: AppState) => getStateData<Form>(state, statePath));
    const dispatch = useDispatch();
    if (config === undefined) throw new Error("No pre-engagement config found.");

    if (isEmpty(formState)) {
        const formData = loadForm(config);
        dispatch(updateFormDefinition(statePath, formData));
        bindForm(formData, dispatch);
    } else {
        bindForm(formState, dispatch);
    }

    return (
        <>
            <Header />
            <NotificationBar />
            {FormBuilder(statePath)}
        </>
    );
}
