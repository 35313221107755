import HtmlReactParser from "html-react-parser";
import { CheckboxField, newCheckboxField } from "../../store/FormBuilder/CheckboxField";
import { HiddenField, newHiddenField } from "../../store/FormBuilder/HiddenField";
import { SelectField, newSelectField } from "../../store/FormBuilder/SelectField";
import { TextAreaField, newTextAreaField } from "../../store/FormBuilder/TextAreaField";
import { TextFieldType, TextField, newTextField } from "../../store/FormBuilder/TextField";

type RawFieldConfigBase = {
    attributes: {
        name: string;
        value?: string;
    };
};

type RawCheckboxConfig = RawFieldConfigBase & {
    type: "CheckboxItem";
    label?: string;
    value?: boolean;
    attributes: {
        required?: boolean;
        indeterminate?: boolean;
    };
};

type RawHiddenConfig = RawFieldConfigBase & {
    type: "InputItem";
    attributes: {
        type: "hidden";
    };
};

type RawInputConfig = RawFieldConfigBase & {
    type: "InputItem";
    label?: string;
    attributes: {
        type?: TextFieldType;
        placeholder?: string;
        required?: boolean;
    };
};

type RawTextAreaConfig = RawFieldConfigBase & {
    type: "TextareaItem";
    label?: string;
    attributes: {
        placeholder?: string;
        required?: boolean;
    };
};

type RawSelectConfig = RawFieldConfigBase & {
    type: "SelectItem";
    label?: string;
    attributes: {
        required?: boolean;
    };
    options: {
        value: string;
        label: string;
    }[];
};

export type RawFieldConfig = RawCheckboxConfig | RawHiddenConfig | RawInputConfig | RawTextAreaConfig | RawSelectConfig;

function isRawCheckboxConfig(config: RawFieldConfig): config is RawCheckboxConfig {
    return config.type === "CheckboxItem";
}

function isRawHiddenConfig(config: RawFieldConfig): config is RawHiddenConfig {
    return config.type === "InputItem" && config.attributes.type === "hidden";
}

function isRawInputConfig(config: RawFieldConfig): config is RawInputConfig {
    return config.type === "InputItem";
}

function isRawTextAreaConfig(config: RawFieldConfig): config is RawTextAreaConfig {
    return config.type === "TextareaItem";
}

function isRawSelectConfig(config: RawFieldConfig): config is RawSelectConfig {
    return config.type === "SelectItem";
}

export function parseField(
    rawConfig: RawFieldConfig
): CheckboxField | TextField | TextAreaField | SelectField | HiddenField {
    if (isRawCheckboxConfig(rawConfig)) {
        return newCheckboxField({
            ...rawConfig.attributes,
            label: HtmlReactParser(rawConfig.label as string)
        });
    }
    if (isRawHiddenConfig(rawConfig)) {
        return newHiddenField({
            ...rawConfig.attributes
        });
    }
    if (isRawTextAreaConfig(rawConfig)) {
        return newTextAreaField({
            ...rawConfig.attributes,
            label: HtmlReactParser(rawConfig.label as string)
        });
    }
    if (isRawInputConfig(rawConfig)) {
        return newTextField({
            ...rawConfig.attributes,
            label: HtmlReactParser(rawConfig.label as string),
            textFieldType: rawConfig.attributes.type
        });
    }
    if (isRawSelectConfig(rawConfig)) {
        return newSelectField({
            ...rawConfig.attributes,
            label: HtmlReactParser(rawConfig.label as string),
            options: Object.fromEntries(rawConfig.options.map(({ value, label }) => [value, label]))
        });
    }
    throw Error("Unsupported field format.");
}

export const preSelectedFieldNames: Record<string, string> = {
    customerName: "friendlyName",
    queue: "type",
    customerEntity: "entity",
    customerGuid: "customer-guid",
    contractStatus: "status",
    equipmentModels: "equipment",
    referenceURL: "uhaulcom-url",
    systemAutoResponse: "canned-message"
};
