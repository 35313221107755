import { ClientApps } from "../store/definitions";
import { store } from "../store/store";

export function getClientApp(): ClientApps {
  let clientApp: ClientApps = ClientApps.DEV;
  try {
    const storeData = store.getState();
    clientApp = process.env.REACT_APP_ENVIRONMENT as ClientApps;
    const consoleMessage = `Please add configuration and use one of the valid values ${Object.keys(ClientApps).join(", ")}.`;

    if (storeData && storeData.config && storeData.config.clientApp) {
      if (Object.values(ClientApps)?.includes(storeData.config.clientApp)) {
        clientApp = storeData.config.clientApp as ClientApps;
      }
      else {
        console.warn(new Error(`clientApp in appConfig has wrong value '${storeData.config.clientApp}'. ${consoleMessage}`));
      }
    }    
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams && urlParams.get("clientApp")) {
      clientApp = urlParams.get("clientApp") as ClientApps;
  }
  } catch (e) {
    console.error(e);
  }
  return clientApp;
}
