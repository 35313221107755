import { Dispatch } from "redux";
import { FileAttachmentConfig } from "../definitions";
import { notifications } from "../notifications";
import { addNotification } from "../store/actions/genericActions";
import { roundFileSizeInMB } from "./roundFileSizeInMB";

/*
 * Extracts and returns an extension from a provided file name 
 */
const fileNameToExtension = (fileName: string) => {
    if(!fileName)
        return "";
    
    const splitFileName = fileName.split(".");
    if(splitFileName?.length < 2)
        return "";

    const extension = splitFileName.pop();   
    if(!extension)
        return "";
    
    return extension.toLowerCase().trim();
}

const fileHasExtension = (file:File, extension: string) => {
    return extension.toLowerCase() === fileNameToExtension(file.name)
}

/*
 * Validates all provided files and shows an error notification for every invalid file.
 * Returns all valid files.
 */
export const validateFiles = (
    files: File[],
    dispatch: Dispatch,
    attachedFiles?: File[],
    fileAttachmentConfig?: FileAttachmentConfig
): File[] => {
    return files.reduce<File[]>((validFilesAcc, file) => {
        if (
            attachedFiles &&
            attachedFiles.some(
                (attachedFile) =>
                    file.name === attachedFile.name &&
                    file.type === attachedFile.type &&
                    file.size === attachedFile.size
            )
        ) {
            dispatch(
                addNotification(
                    notifications.fileAttachmentAlreadyAttachedNotification({
                        fileName: file.name
                    })
                )
            );
        } else if (fileAttachmentConfig?.maxFileSize && file.size > fileAttachmentConfig.maxFileSize) {
            dispatch(
                addNotification(
                    notifications.fileAttachmentInvalidSizeNotification({
                        fileName: file.name,
                        maxFileSize: `${roundFileSizeInMB(fileAttachmentConfig?.maxFileSize)}MB`
                    })
                )
            );
        } else if (
            fileAttachmentConfig?.acceptedExtensions &&
            !fileAttachmentConfig.acceptedExtensions.some((extension: string) => fileHasExtension(file, extension))
        ) {
            dispatch(
                addNotification(
                    notifications.fileAttachmentInvalidTypeNotification({
                        fileName: file.name
                    })
                )
            );
        } else {
            validFilesAcc.push(file);
        }

        return validFilesAcc;
    }, []);
};

/*
 * Validates provided files and shows an error notification for every invalid file.
 * Returns true if valid and false otherwise.
 */
export const validateDownload = (
    file: File,
    dispatch: Dispatch,
    fileAttachmentConfig?: FileAttachmentConfig
): boolean => {
    if (fileAttachmentConfig?.maxFileSize && file.size > fileAttachmentConfig.maxFileSize) {
        dispatch(
            addNotification(
                notifications.fileDownloadInvalidSizeNotification({
                    fileName: file.name,
                    maxFileSize: `${roundFileSizeInMB(fileAttachmentConfig.maxFileSize)}MB`
                })
            )
        );
        return false;
    }

    if (
        fileAttachmentConfig?.acceptedExtensions &&
        !fileAttachmentConfig.acceptedExtensions.some((extension: string) => fileHasExtension(file, extension))
    ) {
        dispatch(
            addNotification(
                notifications.fileDownloadInvalidTypeNotification({
                    fileName: file.name
                })
            )
        );
        return false;
    }
    return true;
}