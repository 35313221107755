import { Button } from "@twilio-paste/core/button";
import { Box } from "@twilio-paste/core/dist/box";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import log from "loglevel";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { END_CHAT_MESSAGE, END_CHAT_WARNING, END_IDLE_CHAT_MESSAGE, END_TASK_REASON, END_IDLE_CHAT_TIMEOUT, END_IDLE_CHAT_QUERY_PARAMETER_TIMEOUT, END_IDLE_CHAT_QUERY_PARAMETER_SHOW } from "../constants";
import { storeChatActive } from "../sessionDataHandler";
import { ACTION_UPDATE_CONVERSATION_STATE } from "../store/actions/actionTypes";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { cancelTask, wrapUpTask, endTask, getTasks, unhookParkedChat } from "../store/actions/taskActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { endChatButtonStyles } from "./styles/EndChatButton.styles";

function loadValuesFromQueryString(idleTimeoutMins: number, showRemainingTime: number) {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams) {
            if (!isNaN(Number(urlParams.get(END_IDLE_CHAT_QUERY_PARAMETER_TIMEOUT))) && Number(urlParams.get(END_IDLE_CHAT_QUERY_PARAMETER_TIMEOUT)) > 0) {
                idleTimeoutMins = Number(urlParams.get(END_IDLE_CHAT_QUERY_PARAMETER_TIMEOUT));
            }
            if (!isNaN(Number(urlParams.get(END_IDLE_CHAT_QUERY_PARAMETER_SHOW)))) {
                showRemainingTime = Number(urlParams.get(END_IDLE_CHAT_QUERY_PARAMETER_SHOW));
            }
        }
    }
    catch (err) {
        log.error(err);
    }
    return { idleTimeoutMins, showRemainingTime };
}

export const EndChatButton = () => {
    const { conversation, conversationState, isParkChatEnabled } = useSelector((state: AppState) => ({
        conversation: state.chat.conversation,
        conversationState: state.chat.conversationState,
        isParkChatEnabled: state.config.isParkChatEnabled
    }));
    const [idleTimerstate, setIdleTimerState] = useState<string>("Active");
    const [remaining, setRemaining] = useState<number>(0);

    let idleTimeoutMins = Number(END_IDLE_CHAT_TIMEOUT);
    let showRemainingTime: number = 0;
    if (!isNaN(Number(process.env.REACT_APP_IDLE_TIMEOUT))) {
        idleTimeoutMins = Number(process.env.REACT_APP_IDLE_TIMEOUT);
    }
    ({ idleTimeoutMins, showRemainingTime } = loadValuesFromQueryString(idleTimeoutMins, showRemainingTime));

    const dispatch = useDispatch();

    const endTasks = async () => {
        const conversationSid = conversation?.sid ?? "";
        return new Promise((resolve) =>
            setTimeout(
                () =>
                    resolve(
                        getTasks({ conversationSid })
                            .then(async (response) => response.json())
                            .then((result) => {
                                if (result?.length > 0) {
                                    const allMatchingChannels = result.filter((task: Record<string, unknown>) => {
                                        const attributes = (task.attributes as string) || "";

                                        return (
                                            attributes.includes(conversationSid) && !attributes.includes("MonitoredBy")
                                        );
                                    });

                                    if (allMatchingChannels?.length > 0) {
                                        
                                        let hasMoreThanOneChats = false;
                                        if(allMatchingChannels?.length >= 2) {
                                            allMatchingChannels.sort(
                                                (a:any, b:any) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
                                            );
                                            hasMoreThanOneChats = true;
                                        }

                                        // if the task is not reserved, then complete the task
                                        allMatchingChannels.forEach((channel:any, index:any) => {
                                            if (
                                                channel.assignmentStatus !== "reserved" &&
                                                channel.assignmentStatus !== "pending"
                                            ) {
                                                if(hasMoreThanOneChats && channel.workflowFriendlyName && channel.taskQueueFriendlyName 
                                                    && channel.workflowFriendlyName !== channel.taskQueueFriendlyName && index > 0)
                                                {
                                                    endTask({
                                                        taskSid: channel.sid,
                                                        reason: END_TASK_REASON
                                                    });
                                                }
                                                else{
                                                    wrapUpTask({
                                                        taskSid: channel.sid,
                                                        reason: END_TASK_REASON
                                                    });
                                                }
                                            } // if task is reserved, then cancel the task.
                                            // eslint-disable-next-line no-eq-null, eqeqeq
                                            else if (channel.sid != null) {
                                                cancelTask({
                                                    taskSid: channel.sid,
                                                    reason: END_TASK_REASON
                                                });
                                            }
                                        })
                                    }
                                }
                            })
                    ),
                100
            )
        ).catch((err) => {
            log.error(err);
        });
    };

    const sendEndMessage = async (message: string) => {
        return new Promise((resolve) => {
            resolve(conversation?.sendMessage(message));
        }).catch((err) => {
            log.error(err);
        });
    };

    const unhookChat = async (webhookSid:string) => {
        return new Promise((resolve) => {
            resolve(unhookParkedChat({ conversationSid: `${conversation?.sid}`, webhookSid}));
        }).catch((err) => {
            log.error(err);
        });
    };

    const restartEngagement = async () => {
        return new Promise((resolve) => {
            resolve(dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm })));
        }).catch((err) => {
            log.error(err);
        });
    };

    const closeConversation = async () => {
        return new Promise((resolve) => {
            resolve(
                dispatch({
                    type: ACTION_UPDATE_CONVERSATION_STATE,
                    payload: { conversationState: "closed" }
                })
            );
        }).catch((err) => {
            log.error(err);
        });
    };

    const endChat = async (message: string) => {
        try {
            if (conversationState === "active") {
                const actions = [];
                if (conversation) {
                    if(conversation.attributes){
                    const attributes = (conversation.attributes as any);
                    if(attributes.webhookSid){
                        //adding in actions doesn't work and sometimes triggers chat upon sendEndMessage.
                        await unhookChat(attributes.webhookSid);
                    }
                }
                    // actions.push(sendEndMessage());
                    await sendEndMessage(message);
                    // actions.push(endTasks());
                    await endTasks();
                    actions.push(closeConversation());
                } else {
                    actions.push(restartEngagement());
                }

                Promise.all(actions).then(() => {
                    storeChatActive(false);
                    //Raise event when chat has ended
                    const rootElement = document.getElementById("twilio-webchat-widget-root");
                    if (rootElement) {
                        const event = new CustomEvent("chat_ended");
                        rootElement.dispatchEvent(event);
                    }
                }).catch((err) => {
                    log.error(err);
                });
            }
        } catch(err){
            log.error(err);
        }
    };

    const endChatPrompt = async () => {
        try {
            // eslint-disable-next-line no-alert
            if (window.confirm(END_CHAT_WARNING)) {
                await endChat(END_CHAT_MESSAGE);
            }
        }
        catch (err) {
            log.error(err);
        }
    };

    const onIdle = async () => {     
        try {           
            setIdleTimerState("Idle");
            if (isParkChatEnabled) {
                await endChat(END_IDLE_CHAT_MESSAGE);
            }
        }
        catch (err) {
            log.error(err);
        }
    }

    const onActive = () => {
        try {
            setIdleTimerState("Active");
        }
        catch(err){
            log.error(err);
        }
        
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        timeout: idleTimeoutMins * 60 * 1000,
        throttle: 500,
        events: ["keydown"],
        element: document.getElementById("twilio-chat-input-box") ?? undefined
    });

    useEffect(() => {
        const interval = setInterval(() => {
            try {            
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
            catch(err){
                log.error(err);
            }
        }, 500);

        return () => {
            try {                
                clearInterval(interval);
            }
            catch(err){
                log.error(err);
            }
        }
    });

    return (
        <>
            <Box {...endChatButtonStyles}>
                <Button variant="primary" onClick={async () => { await endChatPrompt(); }}>
                    <CloseIcon decorative={false} title="End Chat" />
                    <span>End Chat</span>
                </Button>
            </Box>
            {isParkChatEnabled && showRemainingTime > 0 && <p>{remaining} seconds remaining for the currently {idleTimerstate} chat to end</p>}
            {!isParkChatEnabled && showRemainingTime > 0 && <p>Park Chat Feature is not available.</p>}
        </>
    );
};
