import { Field, newField } from "./FormField";
import { NamedElement } from "./NamedElement";

export type TextFieldType = "text" | "email" | "date" | "time" | "tel";

export type TextField = Field & {
    required?: boolean;
    placeholder?: string;
    textFieldType: TextFieldType;
    pattern?: RegExp;
};

export function newTextField(config: Partial<TextField> & NamedElement): TextField {
    return Object.assign(
        newField({
            ...config,
            elementType: "TextField"
        }),
        {
            textFieldType: config.textFieldType ?? "text"
        }
    );
}
