import { Box } from "@twilio-paste/core/box";
import { Label } from "@twilio-paste/core/label";
import { TextArea } from "@twilio-paste/core/textarea";
import { useDispatch, useSelector } from "react-redux";
import type { TextAreaField } from "../../store/FormBuilder/TextAreaField";
import { updateFormValue } from "../../store/actions/genericActions";
import { AppState } from "../../store/definitions";
import { fieldStyles } from "../styles/PreEngagementFormPhase.styles";
import { getStateData } from "./FormBuilder";

function submitOnEnter(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        (e.target as HTMLTextAreaElement).form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
}

export function TextAreaField(statePath: string[]) {
    const dispatch = useDispatch();
    const data = useSelector((state: AppState) => getStateData<TextAreaField>(state, statePath));

    return (
        <Box {...fieldStyles} {...data.boxProps} key={data.key}>
            <Label {...data.labelProps} htmlFor={data.id} required={data.required}>
                {data.label ?? data.name}
            </Label>
            <TextArea
                {...data.itemProps}
                id={data.id}
                name={data.id}
                value={data.value}
                required={data.required}
                placeholder={data.placeholder}
                rows={data.rows}
                onChange={(e) => {
                    dispatch(updateFormValue(statePath, e.target.value));
                    data.onChange?.(e);
                }}
                onKeyPress={submitOnEnter}
            />
        </Box>
    );
}
