import merge from "lodash.merge";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { WebchatWidget } from "./components/WebchatWidget";
import { initLogger } from "./logger";
import { getChatActive, getConversationDetail, sessionDataHandler } from "./sessionDataHandler";
import { initConfig } from "./store/actions/initActions";
import { logException, checkParkedChatFeatureEnabled } from "./store/actions/taskActions";
import { ConfigState } from "./store/definitions";
import { store } from "./store/store";

const defaultConfig: ConfigState = {    
    theme: {
        isLight: true
    },
    fileAttachment: {
        enabled: true,
        maxFileSize: 16777216, // 16 MB
        acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"]
    },
    transcript: {
        emailSubject: (agentNames) => {
            let subject = "Transcript of your chat";
            if (agentNames.length > 0) {
                subject = subject.concat(` with ${agentNames[0]}`);
                agentNames.slice(1).forEach((name) => (subject = subject.concat(` and ${name}`)));
            }
            return subject;
        },
        emailContent: (customerName, transcript) => {
            return `<div><h1 style="text-align:center;">Chat Transcript</h1><p>Hello ${customerName},<br><br>Please see below your transcript, with any associated files attached, as requested.<br><br>${transcript}</p></div>`;
        }
    }
};

const initWebchat = async (
    config: ConfigState & {
        preEngagementConfig: {
            formBuilderConfig: Record<string, unknown> & { elements: Record<string, Record<string, unknown>> };
        };
    }
) => {
    try {
        const parsedConfig: ConfigState = {
            ...config,
            preEngagementConfig: {
                ...config.preEngagementConfig
            }
        };
        const mergedConfig = merge({}, defaultConfig, parsedConfig);
        mergedConfig.serverUrl = process.env.REACT_APP_SERVER_URL;
        mergedConfig.isParkChatEnabled = false;
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams && urlParams.get("apiUrl")) {
            const apiUrl = urlParams.get("apiUrl");
            if (apiUrl && apiUrl.trim().length > 0) {
                mergedConfig.serverUrl = apiUrl;
            }
        }
        sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
        checkParkedChatFeatureEnabled()
        .then(async (res) => res.text())
        .then((data) => {
            if(data && data === "True"){
                mergedConfig.isParkChatEnabled = true;
            }
            store.dispatch(initConfig(mergedConfig));
        })
        .catch(err => {
            logException(err, "checkParkedChatFeatureEnabled");
    });
        

        initLogger();
        const rootElement = document.getElementById("twilio-webchat-widget-root");

        render(
            <Provider store={store}>
                <WebchatWidget />
            </Provider>,
            rootElement
            , () => {
                //Raise event when client has been initialized
                setTimeout(() => {
                    if (rootElement) {
                        Object.assign(rootElement, { IsChatActive: getChatActive })
                        Object.assign(rootElement, { GetConversationDetail: getConversationDetail })
                        const event = new CustomEvent("initialized");
                        rootElement.dispatchEvent(event);
                    }
                }, 100);
            });

        if (window.Cypress) {
            window.store = store;
        }
    }
    catch (err) {
        if (err instanceof Error) {
            logException(err, "initWebchat");
        }
    }
};

declare global {
    interface Window {
        Twilio: {
            initWebchat: (config: ConfigState) => void;
        };
        Cypress: Cypress.Cypress;
        store: typeof store;
    }
}

// Expose `initWebchat` function to window object
Object.assign(window, {
    Twilio: {
        initWebchat
    }
});
