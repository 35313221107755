import { TextStyleProps } from "@twilio-paste/core";
import { BoxStyleProps } from "@twilio-paste/core/box";

export const welcomeMessageStyles: BoxStyleProps = {
    display: "flex",
    alignItems: "center",
    padding: "space30",
    borderRadius: "borderRadius20",
    fontSize: "fontSize30"
};

export const welcomeMessageOffsetStyles: BoxStyleProps = {
    marginLeft: "space20"
};

export const welcomeMessageInlineBlock: TextStyleProps = {
    display: "inline-block"
};
