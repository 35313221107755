import { Element, newElement } from "./Element";
import { TypedElement } from "./TypedElement";

export type RenderedElement = Element & {
    boxProps: Record<string, unknown>;
    itemProps: Record<string, unknown>;
};

export function newRenderedElement(config: Partial<RenderedElement> & TypedElement): RenderedElement {
    return Object.assign(newElement(config), {
        boxProps: { ...config.boxProps },
        itemProps: { ...config.itemProps }
    });
}
