import { MouseEventHandler, ReactElement } from "react";
import { ButtonType, ButtonVariant } from "../../components/FormBuilder/Button";
import { NamedElement } from "./NamedElement";
import { RenderedElement, newRenderedElement } from "./RenderedElement";

export type Button = RenderedElement & {
    content: ReactElement[] | string;
    buttonType: ButtonType;
    variant: ButtonVariant;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

export function newButton(config: Partial<Button> & NamedElement): Button {
    return Object.assign(
        newRenderedElement({
            ...config,
            elementType: "Button"
        }),
        {
            content: config.content ?? "",
            buttonType: config.buttonType ?? "submit",
            variant: config.variant ?? "primary"
        }
    );
}
