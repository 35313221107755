import { Field, newField } from "./FormField";
import { NamedElement } from "./NamedElement";

export type CheckboxField = Field & {
    required?: boolean;
    indeterminate?: boolean;
};

export function newCheckboxField(config: Partial<CheckboxField> & NamedElement): CheckboxField {
    return newField({
        ...config,
        elementType: "CheckboxField"
    });
}
